import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PageContainer } from '../../shared/components';
import SellerPaymentsLevelConfigList from './sellerPaymentsLevelConfigList';
import { useSellerPaymentsConfiguration } from '../../hooks/hooks';

const SellerPaymentsLevelConfig = () => {
  const [apiTrigger, setApiTrigger] = useState(false);

  const [
    { getPaymentConfigListError, getPaymentConfigListLoading, getPaymentConfigListValue },
    { doGetSellerPaymentsConfigList },
  ] = useSellerPaymentsConfiguration();

  useEffect(() => {
    doGetSellerPaymentsConfigList();
  }, [apiTrigger]);
  return (
    <PageContainer>
      <Row>
        <Col className="ml-1 mb-1" md={12}>
          <Row className="align-items-center">
            <Col>
              <h3 className="mt-1 font-bold">Seller payment Configurations</h3>
              <p style={{ fontSize: '16px' }}>
                Manage the level-0 process required for payment approval in the business vertical.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="mt-4" style={{ flex: 1, overflow: 'hidden' }}>
        <SellerPaymentsLevelConfigList
          data={getPaymentConfigListValue}
          isLoading={getPaymentConfigListLoading}
          setApiTrigger={setApiTrigger}
          apiTrigger={apiTrigger}
        />
      </div>
    </PageContainer>
  );
};

export default SellerPaymentsLevelConfig;
