import React, { useMemo, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useTable } from 'react-table';
import { useCatalog, useStakeholders } from '../../hooks';
import { toCapitalize, toLocalDateTimeFormat } from '../../shared/utils/Helper';
import { OverlayCell, PrimaryButton } from '../../shared/components/GenericComponent';
import { EditIconGreen } from '../../assets/img/imageExport/images';
import './sellerPaymentsLevelConfigList.scss';
import { Loader } from '../../shared/components';
import VerticalConfigModal from './verticalConfigModal';
import HasRolePermission from '../../shared/HasRolePermission';
import { ALL_MODULES } from '../../shared/constants/Modules';
import { ALL_PERMISSIONS } from '../../shared/constants/Permissions';

const SellerPaymentsLevelConfigList = ({
  data = [],
  isLoading = false,
  setApiTrigger = () => {},
  apiTrigger,
}) => {
  const { kamList } = useStakeholders();
  const [configModal, setConfigModal] = useState(false);
  const [itemDetails, setItemDetails] = useState({});

  const columns = useMemo(() => {
    return [
      {
        Header: 'Business Vertical',
        accessor: 'businessVertical',
        Cell: ({ row }) => {
          return row?.original?.businessVertical || '-';
        },
      },
      {
        Header: 'Approvers',
        Cell: ({ row }) => {
          const approvers = row?.original?.users?.split(',').map(Number) || [];
          const approverNames = approvers
            .map((id) => kamList.find((kam) => kam?.userId === id)?.firstName)
            .filter(Boolean);
          if (approverNames.length <= 1) {
            return approverNames[0] || '-';
          }
          const otherApprovers = approverNames.slice(1).join(', ');
          const overlayText = `Other approvers: ${otherApprovers}`;
          return (
            <div>
              {approverNames[0] || '-'}
              <OverlayCell overlay={<div>{overlayText}</div>}>
                <Badge className="rounded text-center p-1">+{approvers?.length - 1}</Badge>
              </OverlayCell>
            </div>
          );
        },
      },
      {
        Header: 'Updated On',
        accessor: 'updatedOn',
        Cell: ({ row }) => {
          return row?.original?.updatedOn ? toLocalDateTimeFormat(row?.original?.updatedOn) : '-';
        },
      },
      {
        Header: 'Configuration Status',
        accessor: 'configStatus',
        Cell: ({ row }) => {
          const status = row?.original?.configStatus?.toLowerCase();
          let badgeClass = '';

          if (status === 'disabled') {
            badgeClass = 'badge-default';
          } else if (status === 'enabled') {
            badgeClass = 'badge-enabled';
          }

          return (
            <div className="text-left">
              <Badge pill className={`badge ${badgeClass}`}>
                {toCapitalize(status)}
              </Badge>
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => {
          return (
            <HasRolePermission
              moduleName={ALL_MODULES?.SELLER_PAYMENT_CONFIGURATIONS}
              permissionName={[ALL_PERMISSIONS?.SELLER_PAYMENT_CONFIG_EDIT]}>
              <PrimaryButton
                buttonStyle={{
                  display: 'flex',
                  padding: '8px 12px',
                  backgroundColor: '#fff',
                  color: '#156751',
                  borderRadius: '8px',
                }}
                label={
                  <span>
                    <EditIconGreen /> Edit
                  </span>
                }
                onClick={() => {
                  setItemDetails(row?.original);
                  setConfigModal(true);
                }}
              />
            </HasRolePermission>
          );
        },
      },
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex },
  } = useTable({
    columns,
    data: data || [],
    initialState: { pageIndex: 0, pageSize: 20 },
  });

  return (
    <div className="sellerPaymentsLevelConfigList table-wrapper">
      <div {...getTableProps()} className="table">
        <div className="table-header" style={{ backgroundColor: '#5b7682' }}>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div {...getTableBodyProps()} className="table-body">
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr">
                  {row?.cells.map((cell) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {configModal && (
        <VerticalConfigModal
          configModal={configModal}
          setConfigModal={setConfigModal}
          itemDetails={itemDetails}
          kamList={kamList}
          setApiTrigger={setApiTrigger}
          apiTrigger={apiTrigger}
        />
      )}
    </div>
  );
};

export default SellerPaymentsLevelConfigList;
