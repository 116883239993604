import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormField } from '../../shared/components';
import DropdownSelect from '../../shared/components/DropdownSelect';
import { PrimaryButton, SecondaryButton } from '../../shared/components/GenericComponent';
import { useSellerPaymentsConfiguration } from '../../hooks/hooks';
import { displayToastMessage } from '../../shared/utils/Helper';
import { TOAST_TYPES } from '../../shared/constants/Constants';

const VerticalConfigModal = ({
  configModal,
  setConfigModal,
  itemDetails,
  setApiTrigger = () => {},
  apiTrigger,
  kamList,
}) => {
  const [isEnabled, setIsEnabled] = useState(itemDetails?.configStatus === 'ENABLED');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [
    { putUsersListError, putUsersListLoading, putUsersListValue },
    { doPutUsersList },
  ] = useSellerPaymentsConfiguration();

  useEffect(() => {
    const userIds = itemDetails?.users?.split(',').map(Number) || [];
    setSelectedUsers(kamList.filter((e) => userIds.includes(e?.userId)));
    if (itemDetails?.configStatus === 'ENABLED') {
      setIsEnabled(true);
    }
  }, [itemDetails?.configStatus, itemDetails?.users]);

  const onCancel = () => {
    setConfigModal(false);
  };

  const onSave = async () => {
    const payload = {
      id: itemDetails?.id,
      businessVertical: itemDetails?.businessVertical,
      configStatus: isEnabled ? 'ENABLED' : 'DISABLED',
      users: isEnabled ? selectedUsers.map((e) => e?.userId).join(',') : '',
    };
    const res = await doPutUsersList(payload);
    if (res?.status === 200) {
      setApiTrigger(!apiTrigger);
    } else {
      displayToastMessage(TOAST_TYPES?.ERROR, res?.data?.message, false);
    }
    setConfigModal(false);
  };

  useEffect(() => {
    if (!isEnabled && itemDetails?.configStatus) {
      setSelectedUsers([]);
    }
  }, [isEnabled]);

  return (
    <Modal centered size="md" show={configModal} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{itemDetails?.businessVertical} Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formEnableSwitch">
            <Form.Check
              type="switch"
              label={isEnabled ? 'Enabled' : 'Disabled'}
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
            />
            <Form.Text muted>Level-0 Approval {isEnabled ? 'Enabled' : 'Disabled'}.</Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <FormField label="User Approvers">
              <DropdownSelect
                isDisabled={!isEnabled}
                options={kamList}
                placeholder="Select Users"
                isMulti
                isClearable
                value={selectedUsers}
                onChange={(selected) => {
                  if (selected.length <= 5) {
                    setSelectedUsers(selected);
                  } else {
                    displayToastMessage(
                      TOAST_TYPES.WARN,
                      'You can select up to 5 users only.',
                      false
                    );
                  }
                }}
                getOptionLabel={(opt) => opt?.firstName}
                getOptionValue={(opt) => opt?.id}
              />
            </FormField>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton buttonStyle={{ borderRadius: '8px' }} label="Cancel" onClick={onCancel} />
        <PrimaryButton
          buttonStyle={{ borderRadius: '8px' }}
          label="Save"
          onClick={onSave}
          disabled={isEnabled && selectedUsers.length === 0}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default VerticalConfigModal;
